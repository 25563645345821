import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emailTopic", "emailDiagnosis", "summernote", "form", "selectElement", "emailCategory", "diagnosisGroup", "customerServiceTopics", "repairServiceTopics", "newCustomerServiceTopic", "newRepairServiceTopic"];

  connect() {
    // Initialize Summernote if the target exists
    if (this.hasSummernoteTarget) {
      $(this.summernoteTarget).summernote({
        height: 300,  
        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link']],
          ['view', ['codeview']],
        ],
        styleTags: [
          'p',
          {
            tag: 'p',
            title: 'No Spacing',
            className: 'MsoNoSpacing'
          }
        ],
        codeviewFilter: false, 
        codeviewFilterRegex: ''
      });
    }

    // Bind form submit to update the content from Summernote
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('submit', (event) => {
        if (this.hasSummernoteTarget) {
          var summernoteContent = $(this.summernoteTarget).summernote('code');
          this.summernoteTarget.value = summernoteContent;
        }
      });
    }

    // Handle dropdown topic selection if the target exists
    if (this.hasCustomerServiceTopicsTarget) {
      this.customerServiceTopicsTarget.addEventListener('change', this.updateTopic.bind(this));
    }
    if (this.hasRepairServiceTopicsTarget) {
      this.repairServiceTopicsTarget.addEventListener('change', this.updateTopic.bind(this));
    }

    // Handle dropdown diagnosis selection if the target exists
    if (this.hasSelectElementTarget && this.hasEmailDiagnosisTarget) {
      this.selectElementTarget.addEventListener('change', () => {
        this.emailDiagnosisTarget.value = this.selectElementTarget.value;
      });
    }

    // Toggle visibility of repair_type and diagnosis fields
    if (this.hasEmailCategoryTarget) {
      this.emailCategoryTarget.addEventListener('change', this.toggleRepairFields.bind(this));
      this.toggleRepairFields(); // Initial check
    }
  }

  toggleRepairFields() {
    if (this.hasEmailCategoryTarget) {
      if (this.emailCategoryTarget.value === 'customer_service') {
        if (this.hasDiagnosisGroupTarget) this.diagnosisGroupTarget.style.display = 'none';
        if (this.hasCustomerServiceTopicsTarget) this.customerServiceTopicsTarget.style.display = 'inline-flex';
        if (this.hasRepairServiceTopicsTarget) this.repairServiceTopicsTarget.style.display = 'none';
      } else if (this.emailCategoryTarget.value === 'repair_service') {
        if (this.hasDiagnosisGroupTarget) this.diagnosisGroupTarget.style.display = 'block';
        if (this.hasCustomerServiceTopicsTarget) this.customerServiceTopicsTarget.style.display = 'none';
        if (this.hasRepairServiceTopicsTarget) this.repairServiceTopicsTarget.style.display = 'inline-flex';
      }
    }
  }

  toggleNewTopic(event) {
    const button = event.currentTarget;
    const select = button.previousElementSibling;
    const textField = button.nextElementSibling;

    if (select.style.display !== 'none') {
      select.style.display = 'none';
      textField.style.display = 'inline-flex';
      button.textContent = 'Cancel';
    } else {
      select.style.display = 'inline-flex';
      textField.style.display = 'none';
      button.textContent = 'New';
    }
  }

  updateTopic(event) {
    if (this.hasEmailTopicTarget) {
      this.emailTopicTarget.value = event.target.value;
    }
  }
}