import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ticket-preview"
export default class extends Controller {
  static targets = ["container", "closeButton"]
  frameOpen = false;
  selected = null;

  connect() {
    console.log('ticket preview controller connected');
    document.addEventListener('keydown', this.keyDown.bind(this));
  }

  disconnect() {
    document.removeEventListener('keydown', this.keyDown.bind(this));
  }

  close() {
    $(this.containerTarget).find('turbo-frame').attr('src', '');
    this.containerTarget.style.display = 'none';
    this.frameOpen = false
  }

  show(event) {
    if (!event.target.classList.contains('td')) { return }
    let row = $(event.target).closest('.tr');
    let url = row.data('url');
    let id = row.attr('id').split('_')[1];
    $('.tr').removeClass('selected');
    row.addClass('selected');
    this.selected = id;
    this.containerTarget.style.display = 'block';
    $(this.containerTarget).find('turbo-frame').attr('src', url);
    this.frameOpen = true;
    // scroll to the top of the container, smoothly
    this.containerTarget.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  showNext() {
    let selectedRow = $(`#ticket_${this.selected}`);
    let nextRow = selectedRow.next('.tr');
    if (nextRow.length > 0) {
      this.select(selectedRow, nextRow);
    }
  }

  showPrevious() {
    let selectedRow = $(`#ticket_${this.selected}`);
    let previousRow = selectedRow.prev('.tr');
    if (previousRow.length > 0) {
      this.select(selectedRow, previousRow);
    }
  }

  select(selectedRow, rowToSelect) {
    this.selected = rowToSelect.attr('id').split('_')[1];
    $(this.containerTarget).find('turbo-frame').attr('src', rowToSelect.data('url'));
    $(this.containerTarget).find('turbo-frame').html('<div class="anim-container"><div class="anim"><span></span> <span></span> <span></span></div></div>');
    selectedRow.removeClass('selected');
    rowToSelect.addClass('selected');
    this.setScroll(rowToSelect.offset().top - 100);
  }

  setScroll(offset) {
    window.scrollTo({
      top: offset,
      left: 0,
      behavior: 'smooth'
    });
  }

  keyDown(event) {
    if (this.frameOpen) {
      
      if (event.key === "ArrowRight" || event.key === "ArrowDown") {
        event.preventDefault();
        this.showNext();
      } else if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
        event.preventDefault();
        this.showPrevious();
      } else if (event.key === "Escape") {
        event.preventDefault();
        this.close();
      }
    }
  }

  keyUp(event) {
    // do nothing for now
  }
}
