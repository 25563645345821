import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="xlsx-form"
export default class extends Controller {
  static targets = [ "fileInput", "sheetPreview" ]
  static values = { tabs: Array }
  connect() {
    this.sheetPreviewTarget.innerHTML = "";
  }

  readFile(event) {
    this.tabsValue = [];
    var XLSX = require('xlsx');
    var workbook = event.target.files[0];

    if (workbook) {
      var reader = new FileReader();
      var self = this;
      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {type: 'binary'});
        var tabs = [];
        workbook.SheetNames.forEach(function(sheetName) {

          var json_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1});
          var json_object = json_object.slice(0, 10);
          var tab = self.generateSheetPreview(sheetName, json_object);
          tabs.push({name: sheetName, preview: tab});

          console.log(json_object);
        })

        self.sheetPreviewTarget.innerHTML = self.populateSheets(tabs);
      };

     
      reader.onerror = function(ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(workbook);
    }
  }

  populateSheets(tabs) {
    console.log(tabs)
    var tab_headers = "";
    tab_headers += "<ul class='nav nav-tabs m-tabs m-tabs-line m-tabs-line--right' id='myTab' role='tablist'>";
    for (var i = 0; i < tabs.length; i++) {
      tab_headers += "<li class='nav-item' role='presentation'>";
      tab_headers += "<input type='checkbox' name='" + i +"_checkbox' id='tab" + i + "_checkbox' class='tab_checkbox' checked>";
      if (i == 0) {
        tab_headers += "<a class='nav-link active' id='tab" + i + "-tab' data-toggle='tab' href='#tab" + i + "' role='tab' aria-controls='tab" + i + "' aria-selected='true'>" + tabs[i].name + "</a>";
      } else {
        tab_headers += "<a class='nav-link' id='tab" + i + "-tab' data-toggle='tab' href='#tab" + i + "' role='tab' aria-controls='tab" + i + "' aria-selected='false'>" + tabs[i].name + "</a>";
      }
    }
    tab_headers += "</ul>"

    var tab_contents = "";
    tab_contents += "<div class='tab-content' id='myTabContent'>";
    for (var i = 0; i < tabs.length; i++) {
      if (i == 0) {
        tab_contents += "<div class='tab-pane show active' id='tab" + i + "' role='tabpanel' aria-labelledby='tab" + i + "-tab' aria-expanded='true'>";
      } else {
        tab_contents += "<div class='tab-pane fade' id='tab" + i + "' role='tabpanel' aria-labelledby='tab" + i + "-tab'>";
      }
      tab_contents += tabs[i].preview;
      tab_contents += "</div>";
    }
    tab_contents += "</div>";

    return tab_headers + tab_contents;
  }

  generateSheetPreview(sheetName, json_object) {
    if(json_object.length == 0) {
      return "";
    }
    var field_selector = $("#field_selector").html();
    var model_selector = this.preselected_model(sheetName);

    var preview = "";
    preview += "<h2>" + sheetName + "</h2>";
    preview += model_selector;
    preview += "<table class='table table-bordered table-striped'>";
    preview += "<thead>";
    for (var i = 0; i < json_object[0].length; i++) {
      var custom_field_selector = field_selector.replace("id=\"field\"", "id=\"" + sheetName + "_field_" + i + "\"");
      custom_field_selector = custom_field_selector.replace("name=\"field\"", "name=\"" + sheetName + "_field_" + i + "\"");
      preview += "<th>" + custom_field_selector + "</th>";
    }
    preview += "</thead>";
    preview += "<tbody>";
    for (var i = 0; i < json_object.length; i++) {
      preview += "<tr>";
      for (var j = 0; j < json_object[i].length; j++) {
        preview += "<td>" + json_object[i][j] + "</td>";
      }
      preview += "</tr>";
    }
    preview += "</tbody>";
    preview += "</table>";
    return preview;

  }

  preselected_model(sheetName) {
    var model_selector = $('#model_selector').find('select').clone().removeClass('hidden')
    model_selector.attr('id', sheetName + "_model");
    model_selector.attr('name', sheetName + "_model")
    model_selector.attr('required', 'required');
    model_selector = model_selector[0];
    for (var i = 0; i < model_selector.length; i++) {
      console.log(model_selector.options[i])
      if (model_selector.options[i].text.toLowerCase() == sheetName.toLowerCase()) {
        
        model_selector.options[i].selected = true;
        model_selector.options[i].setAttribute('selected', 'selected');
        break;
      }
    }
    return model_selector.outerHTML;
  }
}
